import React, { useState } from 'react'

import FormField from './Form/Field'
import FormLabel from './Form/Label'
import FormInput from './Form/Input'
import FormTextarea from './Form/Textarea'
import FormError from './Form/Error'

export default function ContactsForm() {
    const [name, onChangeName] = useState('');
    const [email, onChangeEmail] = useState('');
    const [content, onChangeContent] = useState('');
    const [isFormSubmitted, onFormSubmit] = useState(false)
    const [messageSent, setMessageSent] = useState(false)
    const [messageError, setMessageError] = useState(false)

    const isValidName = () => name && name !== ''
    const isValidEmail = () => email && email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    const isValidContent = () => content && name !== ''

    const handleSubmit = (e) => {
        e.preventDefault()

        onFormSubmit(true)
        setMessageSent(false)
        setMessageError(false)

        if (isValidName() && isValidEmail && isValidContent) {
            fetch('sendMail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    name: name,
                    email: email,
                    content: content
                })
            })
                .then(response => {
                    response.json()
                })
                .then(({ status }) => {
                    if (status) {
                        setMessageSent(true)
                        setMessageError(false)
                    }
                    else {
                        setMessageSent(false)
                        setMessageError(true)
                    }
                })
                .catch(error => {
                    setMessageSent(false)
                    setMessageError(true)
                })
        }
    }

    return (
        <form action="/">
            <FormField>
                <FormLabel name="name">Nome</FormLabel>
                <FormInput
                    name="name"
                    placeholder="Il tuo nome"
                    value={name}
                    onChange={onChangeName}
                    hasError={isFormSubmitted && !isValidName()}>
                </FormInput>
                {isFormSubmitted && !isValidName() && (<FormError>Inserisci un nome valido.</FormError>)}
            </FormField>
            <FormField>
                <FormLabel name="email">Email</FormLabel>
                <FormInput
                    name="name"
                    placeholder="Il tuo indirizzo email"
                    value={email}
                    onChange={onChangeEmail}
                    hasError={isFormSubmitted && !isValidEmail()}>
                </FormInput>
                {isFormSubmitted && !isValidEmail() && (<FormError>Inserisci un indirizzo email valido.</FormError>)}
            </FormField>
            <FormField>
                <FormLabel name="content">Messaggio</FormLabel>
                <FormTextarea
                    name="content"
                    placeholder="Vorrei informazioni su..."
                    value={content}
                    onChange={onChangeContent}
                    hasError={isFormSubmitted && !isValidContent()}>
                </FormTextarea>
                {isFormSubmitted && !isValidContent() && (<FormError>Inserisci un messaggio per noi.</FormError>)}
            </FormField>
            <FormField>
                <button className="button" onClick={handleSubmit}>Invia</button>
            </FormField>
            {messageSent && <p className="msg msg--sent">Grazie di averci contattato! Ti risponderemo non appena possibile.</p>}
            {messageError && <p className="msg msg--error">Si è verificato un errore nell'invio della mail.<br />Riprova tra qualche secondo oppure ricarica la pagina.</p>}
        </form>
    )
}