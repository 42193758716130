import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Section from '../components/Section'
import SectionTitle from '../components/Section/Title'
import SectionImage from '../components/Section/Image'
import SectionDescription from '../components/Section/Description'
import SectionContent from '../components/Section/Content'
import HomeIntro from '../components/Home/Intro'

import precisione from '../../media/precisione.jpg'
import attrezzatura from '../../media/attrezzatura.jpg'
import sicurezza from '../../media/sicurezza.jpg'
import duraturo from '../../media/duraturo.jpg'
import cantieri from '../../media/cantieri.jpg'
import contatti from '../../media/contatti.jpg'

export default function Home() {

    const getImageByType = type => {
        switch (type) {
            case 'cantieri':
                return cantieri;
            case 'contatti':
                return contatti;
            default:
                return cantieri;
        }
    }

    const [image, setImage] = useState(getImageByType(cantieri))

    const handleMouseEnter = (type) => {
        setImage(getImageByType(type))
    }

    const handleMouseLeave = () => {
        setImage(getImageByType(null))
    }

    return (
        <main>
            <HomeIntro></HomeIntro>

            <Section>
                <SectionContent>
                    <SectionTitle>Curiamo i dettagli</SectionTitle>
                    <SectionDescription>
                        <p>Edilnicola sa bene che sono i dettagli a fare la differenza. Non lasciamo nulla al caso progettando tutto nei minimi particolari, dalla prima mano di stucco alla verniciatura.<br /></p>
                        <p>I nostri clienti possono testimoniare per noi la qualità del nostro operato.</p>
                    </SectionDescription>
                </SectionContent>
                <SectionImage image={precisione}></SectionImage>
            </Section>

            <Section>
                <SectionContent>
                    <SectionTitle>Qualsiasi intervento</SectionTitle>
                    <SectionDescription>
                        <p>Siamo in grado di svolgere qualunque tipo di intervento edile, dalla muratura all'impiantistica idrica ed elettrica, dalle riparazioni alle costruzioni.<br /></p>
                        <p>Utilizziamo solo i migliori materiali che acquistiamo da fornitori esperti come <a className="c-white" href="http://www.edilcelle.bigmat.it/site/home.html" target="_blank">Edilcelle</a>.<br /><br />Utilizziamo attrezzatura di alta qualità <a href="https://www.hilti.it/" target="_blank" className="c-white">Hilti</a>.</p>
                    </SectionDescription>
                </SectionContent>
                <SectionImage image={attrezzatura}></SectionImage>
            </Section>

            <Section>
                <SectionContent>
                    <SectionTitle>Fatti per durare</SectionTitle>
                    <SectionDescription>
                        <p>Eseguiamo interventi professionali con assoluta dedizione.<br /></p>
                        <p>Affidarti a noi è <Link to="/cantieri" className="c-white">garanzia di un lavoro duraturo</Link>, fatto per restare. Provare per credere. </p>
                    </SectionDescription>
                </SectionContent>
                <SectionImage image={duraturo}></SectionImage>
            </Section>

            <Section>
                <SectionContent>
                    <SectionTitle>Operiamo in sicurezza</SectionTitle>
                    <SectionDescription>
                        <p>Rispettiamo i più alti standard di sicurezza. La salute dei nostri operai non passa mai in secondo piano.<br /></p>
                        <p>I nostri professionisti hanno alle spalle migliaia di ore di interventi con ridottissime occorrenze di infortunio.</p>
                    </SectionDescription>
                </SectionContent>
                <SectionImage image={sicurezza}></SectionImage>
            </Section>

            <Section>
                <SectionContent>
                    <SectionTitle>Vuoi saperne di più?</SectionTitle>
                    <Link to="/cantieri" className="button" onMouseEnter={() => handleMouseEnter('cantieri')} onMouseLeave={handleMouseLeave}>Scopri i nostri cantieri</Link>
                    <Link to="/contatti" className="button" onMouseEnter={() => handleMouseEnter('contatti')} onMouseLeave={handleMouseLeave}>Contattaci</Link>
                </SectionContent>
                {image && <SectionImage image={image}></SectionImage>}
            </Section>
        </main>
    )
}