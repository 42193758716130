import React from 'react'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container footer__container">
                <p className="footer__text">
                    <span className="c-white">© 2018 Edilnicola di Doci Kolec</span> - via XX Settembre 20, 17100 - Savona (SV) | P.IVA 01549630091</p>
                <p className="footer__text">
                    Site by&nbsp;<a className="c-white" href="http://alessiosantangelo.it" target="_blank" rel="noopener noreferrer">Alessio Santangelo</a>
                </p>
            </div>
        </footer>
    )
}