import React from 'react'

export default function Label(props) {
    return (
        <label
            className="form__field__label c-white"
            htmlFor={props.name}
        >
            {props.children}
        </label>
    )
}