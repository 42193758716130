import React, { useState } from 'react'

export default function Textarea(props) {
    const handleChange = (e) => props.onChange(e.target.value)
    const getClass = () => `form__field__textarea ${props.hasError ? 'has-error' : ''}`

    return (
        <textarea
            className={getClass()}
            type="text"
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleChange}
            autoComplete="off"
            required >
        </textarea>
    )
}