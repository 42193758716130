import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../media/logo.svg'
import phone from '../../media/phone.svg'
import placeholder from '../../media/placeholder.svg'

export default function Nav() {
    const [isMenuOpen, onToggleMenu] = useState(false)

    const getMenuClass = () => `nav__menu ${isMenuOpen ? 'is-open' : ''}`

    return (
        <header>
            <nav className="nav">
                <div className="container nav__container">
                    <Link to="/" className="nav__logo">
                        <img className="nav__logo__img" src={logo} alt="Edilnicola Logo" />
                        <span className="nav__logo__text">Edilnicola</span>
                    </Link>
                    <ul className="nav__contacts" role="menu">
                        <li>
                            <span className="nav__contacts__circle icon-circle">
                                <img className="nav__contacts__img icon-circle__img" src={phone} alt="Edilnicola tel." />
                            </span>
                            <a href="tel:+393391278129" className="no-underline">+39 339 12 78 129</a>
                        </li>
                        <li>
                            <span className="nav__contacts__circle icon-circle">
                                <img className="nav__contacts__img icon-circle__img" src={placeholder} alt="Edilnicola Savona e provincia" />
                            </span>
                            <Link to="/contatti" className="no-underline">Savona e provincia</Link>
                        </li>
                    </ul>

                    <span className="nav__menu__toggle" onClick={() => onToggleMenu(!isMenuOpen)}>
                        Menu
                    </span>

                    <ul className={getMenuClass()}>
                        <li className="nav__menu__item">
                            <div className="container">
                                <Link to="/cantieri" className="nav__menu__item__link no-underline" >Cantieri</Link>
                            </div>
                        </li>
                        <li className="nav__menu__item">
                            <div className="container">
                                <Link to="/contatti" className="nav__menu__item__link no-underline" >Contatti</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}