import React from 'react'

export default function Input(props) {
    const handleChange = (e) => props.onChange(e.target.value)
    const getClass = () => `form__field__input ${props.hasError ? 'has-error' : ''}`

    return (
        <input
            className={getClass()}
            type="text"
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleChange}
            autoComplete="off"
            required >
        </input>
    )
}