import React from 'react'

import Section from '../components/Section'
import SectionTitle from '../components/Section/Title'
import SectionContent from '../components/Section/Content'
import ContactForm from '../components/Form'

export default function Contacts() {
    return (
        <Section>
            <SectionContent>
                <SectionTitle>I nostri contatti</SectionTitle>
                <p>Richiedici un preventivo &nbsp;<span className="c-white">senza impegno e gratuitamente</span>.<br /><br />Se invece vuoi semplicemente farci una domanda, <span className="c-white">non esitare!</span> Saremo lieti di risponderti nel più breve tempo possibile.</p>
                <ContactForm></ContactForm>
            </SectionContent>
        </Section>
    )
}