import React from 'react'

import { Link } from 'react-router-dom'

import logo from '../../../media/logo.svg'
import progetto from '../../../media/progetto.jpg'

export default function Intro() {
    return (
        <section className="intro" style={{ backgroundImage: `url(${progetto})` }}>
            <div className="intro__wrapper">
                <img className="intro__logo" src={logo} alt="Logo" />
                <h1 className="intro__title h1 c-white">Azienda edile <span className="c-red">Edilnicola</span></h1>

                <div className="intro__footer">
                    <Link to="/cantieri" className="button">Cantieri</Link>
                    <Link to="/contatti" className="button">Contattaci</Link>
                </div>
            </div>
        </section>
    )
}